import {
  LOGIN_EXITOSO,
  OBTENER_USUARIO,
  ACTIVAR_CUENTA,
  LOGIN_ERROR,
  CERRAR_SESION,
  CLEAN_STATUS,
  CLEAN_MENSAJE_ERROR,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LOGIN_EXITOSO:
    case ACTIVAR_CUENTA:
      localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        usuario: action.payload,
        autenticado: true,
        mensaje: null,
        cargando: false,
        status: action.daolyap
      };
    case OBTENER_USUARIO:
      return {
        ...state,
        autenticado: true,
        usuario: action.payload,
        cargando: false,
      };
    case LOGIN_ERROR:
    case CERRAR_SESION:
      localStorage.clear();
      return {
        ...state,
        token: null,
        usuario: null,
        autenticado: null,
        mensaje: action.payload,
        cargando: false,
      };
      case CLEAN_MENSAJE_ERROR: 
      return {
        ...state,
        mensaje: null
      }
      case CLEAN_STATUS:
        return {
          ...state,
          cleanStatus: true
        };

    default:
      return state;
  }
};
