import React, { useReducer } from "react";
import PrestamoContext from "./prestamoContext";
import PrestamoReducer from "./prestamoReducer";

import {
  LISTADO_PRESTAMOS,
  REGISTRAR_PRESTAMO,
  PRESTAMO_ERROR,
  FORMULARIO_PRESTAMO,
  ELIMINAR_PRESTAMO,
  PRESTAMO_ACTUAL,
  GENERAR_PDF_PRESTAMO,
  LIMPIAR_GENERAR_PRESTAMO,
  PRESTAMOS_ESTABLECIMIENTOS,
  LIMPIAR_STATUS,
  PDF_PRESTAMO_EXISTENTE,
} from "../../types";

import clienteAxios from "../../config/axios";
import swal from "sweetalert";

const PrestamoState = (props) => {
  const initialState = {
    formulario: false,
    errorformulario: false,
    prestamos: [],
    prestamo: null,
    prestamoSeleccionado: null,
    generarpdfprestamo: null,
    estadoModal: true,
    prestamosestablecimientos: [],
    respuestaEliminar: null,
    statusAgregar: null,
  };

  const [state, dispatch] = useReducer(PrestamoReducer, initialState);

  // Funciones
  const mostrarFormulario = () => {
    dispatch({
      type: FORMULARIO_PRESTAMO,
    });
  };

  // LISTA DE PRESTAMOS
  const listaPrestamos = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/prestamos");
      dispatch({
        type: LISTADO_PRESTAMOS,
        payload: respuesta.data,
      });
    } catch (error) {}
  };
  // FIN LISTA DE PRESTAMOS

  // PRESTAMOS SUCURSAL SELECICONADA
  const prestamosEstablecimientos = async (id) => {
    try {
      const respuesta = await clienteAxios.get(`/api/prestamosTodo/${id}`);
      dispatch({
        type: PRESTAMOS_ESTABLECIMIENTOS,
        payload: respuesta.data,
      });
    } catch (error) {}
  };

  // DEVOLVER ARTICULO

  // AGREGAR NUEVO PRESTAMO
  const agregarPrestamo = async (prestamo) => {
    try {
      const resultado = await clienteAxios.post("/api/prestamos", prestamo);
      const alerta = swal({
        title: "¡Añadido!",
        text: "Este mesaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
      // Insertar la prestamo en el state
      dispatch({
        type: REGISTRAR_PRESTAMO,
        payload: resultado.data,
        alerta,
      });
      // prestamosEstablecimientos(prestamo)
    } catch (error) {
      const alerta = swal({
        title: `${error.response.data}`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN AGREGAR NUEVO PRESTAMO

  // LIMPIAR GENERAR PRESTAMO
  // Funciones
  const limpiarGenerarPrestamo = () => {
    dispatch({
      type: LIMPIAR_GENERAR_PRESTAMO,
    });
  };

  const limpiarStatus = () => {
    dispatch({
      type: LIMPIAR_STATUS,
    });
  };

  // AGREGAR NUEVO PRESTAMO
  const generarPDFPrestamo = async (prestamo) => {
    try {
      const resultado = await clienteAxios.post("/api/prestamopdf", prestamo);
      const alerta = swal({
        title: "¡Se ha generado el documento!",
        text: "Este mesaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
      // Insertar la prestamo en el state
      dispatch({
        type: GENERAR_PDF_PRESTAMO,
        payload: resultado.data,
        status: resultado.status,
        alerta,
      });
      prestamosEstablecimientos(prestamo.unidad);
    } catch (error) {
      const alerta = swal({
        title: `No se pudo generar el reporte, intente nuevamente!`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "error",
        timer: 6000,
        button: false,
      });
      dispatch({
        type: PRESTAMO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN AGREGAR NUEVO PRESTAMO

  // ELIMINAR UN PRESTAMO
  const eliminarPrestamo = async (id) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Una vez eliminado, no podra recuperar los datos!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          const resultado = await clienteAxios.delete(`/api/prestamos/${id}`);
          dispatch({
            type: ELIMINAR_PRESTAMO,
            payload: id,
            respuestaEliminar: resultado.status,
          });
          swal({
            title: `¡Eliminado!`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: `${error.response.data}`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };
  // FIN ELIMINAR UN PRESTAMO

  // PRESTAMOS SUCURSAL SELECICONADA
  // const detallesPrestamo = async (id) => {
  //   try {
  //     const respuesta = await clienteAxios.get(`/api/prestamos/${id}`);
  //     dispatch({
  //       type: PRESTAMOS_ESTABLECIMIENTOS,
  //       payload: respuesta.data,
  //     });
  //   } catch (error) {}
  // };

  // GUARDAR PRESTAMO SELECCIONADO
  const guardarPrestamoActual = (usuario) => {
    dispatch({
      type: PRESTAMO_ACTUAL,
      payload: usuario,
    });
  };
  // FIN GUARDAR PRESTAMO SELECCIONADO

  // GENERAR PDF PRESTAMO SELECCIONADO
  const pdfPrestamoExistente = async (id) => {
    try {
      const resultado = await clienteAxios.get(`/api/generarpdf/${id}`);
      dispatch({
        type: PDF_PRESTAMO_EXISTENTE,
        payload: resultado.data,
      });
    } catch (error) {
    }
  };
  // GENERAR PDF PRESTAMO SELECCIONADO

  return (
    <PrestamoContext.Provider
      value={{
        prestamosestablecimientos: state.prestamosestablecimientos,
        prestamoSeleccionado: state.prestamoSeleccionado,
        generarpdfprestamo: state.generarpdfprestamo,
        respuestaEliminar: state.respuestaEliminar,
        errorformulario: state.errorformulario,
        statusAgregar: state.statusAgregar,
        estadoModal: state.estadoModal,
        formulario: state.formulario,
        prestamos: state.prestamos,
        prestamo: state.prestamo,
        limpiarStatus,
        listaPrestamos,
        agregarPrestamo,
        eliminarPrestamo,
        mostrarFormulario,
        generarPDFPrestamo,
        pdfPrestamoExistente,
        guardarPrestamoActual,
        limpiarGenerarPrestamo,
        prestamosEstablecimientos,
      }}
    >
      {props.children}
    </PrestamoContext.Provider>
  );
};

export default PrestamoState;
