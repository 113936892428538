import React, { useReducer } from "react";
import CodigoContext from "./codigoContext";
import CodigoReducer from "./codigoReducer";

import {
  ABRIR_MODAL,
  CODIGO_ERROR,
  CERRAR_MODAL,
  CODIGO_ACTUAL,
  ELIMINAR_CODIGO,
  LISTADO_CODIGOS,
  REGISTRAR_CODIGO,
  ACTUALIZAR_CODIGO,
  FORMULARIO_CODIGO,
  CLEAN_STATUS_RESPUESTA,
} from "../../types";

import clienteAxios from "../../config/axios";
import swal from "sweetalert";

const CodigoState = (props) => {
  const initialState = {
    formulario: false,
    errorformulario: false,
    codigos: [],
    codigo: null,
    codigoSeleccionado: null,
    estadoModal: false,
    statusRespuesta: null,
    tipoModal: null,
  };

  const [state, dispatch] = useReducer(CodigoReducer, initialState);

  // FUNCIONES QUE MODIFICAN EL STATE

  // MOSTRAR FORMULARIO
  const mostrarFormulario = () => {
    dispatch({
      type: FORMULARIO_CODIGO,
    });
  };
  // FIN MOSTRAR FORMULARIO

  // LISTADO CODIGOS
  const listaCodigos = async () => {
    try {
      const respuesta = await clienteAxios.get("/api/codigos");
      dispatch({
        type: LISTADO_CODIGOS,
        payload: respuesta.data,
      });
    } catch (error) {}
  };
  // FIN LISTADO CODIGOS

  const cleanStatusRespuesta = () => {
    dispatch({
      type: CLEAN_STATUS_RESPUESTA,
      payload: null,
    });
  };

  // AGREGAR NUEVO CODIGO
  const agregarCodigo = async (codigo) => {
    try {
      const resultado = await clienteAxios.post("/api/codigos", codigo);
      const alerta = swal({
        title: `¡Código añadido!`,
        text: "Este mensaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
      // Insertar la codigo en el state
      dispatch({
        type: REGISTRAR_CODIGO,
        payload: resultado,
        alerta,
      });
      listaCodigos();
    } catch (error) {
      const alerta = swal({
        title: `¡Ocurrio un error, intente nuevamente!`,
        text: "Este mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
      dispatch({
        type: CODIGO_ERROR,
        payload: alerta,
      });
    }
  };
  // FIN AGREGAR NUEVO CODIGO

  // Edita o modifica un codigo
  const actualizarCodigo = async (codigo) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Está a punto de modificar un código!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Modificar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        const resultado = await clienteAxios.put(
          `/api/codigos/${codigo.id}`,
          codigo
        );
        if (willDelete && resultado.status === 200) {
          dispatch({
            type: ACTUALIZAR_CODIGO,
            payload: resultado,
          });

          swal({
            title: `${resultado.data === "ok" ? "¡Código modificado!" : ""}`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
          listaCodigos();
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: "Hubo un error, intente nuevamente",
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };

  // ELIMINAR UN CODIGO
  const eliminarCodigo = async (id) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Una vez eliminado, no podra recuperar los datos!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await clienteAxios.delete(`/api/codigos/${id}`);
          dispatch({
            type: ELIMINAR_CODIGO,
            payload: id,
          });
          swal({
            title: `¡Código Eliminado!`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: `Ocurrio un error inesperado, intente nuevamente`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };
  // FIN ELIMINAR UN CODIGO

  // GUARDAR CODIGO SELECCIONADO
  const guardarCodigoActual = (codigo) => {
    dispatch({
      type: CODIGO_ACTUAL,
      payload: codigo,
    });
  };
  // FIN GUARDAR USDESCUENTOUARIO SELECCIONADO

  const abrirModal = (categoriaModal) => {
    dispatch({
      type: ABRIR_MODAL,
      payload: categoriaModal,
    });
  };
  const cerrarModal = () => {
    dispatch({
      type: CERRAR_MODAL,
    });
  };

  return (
    <CodigoContext.Provider
      value={{
        codigoSeleccionado: state.codigoSeleccionado,
        errorformulario: state.errorformulario,
        statusRespuesta: state.statusRespuesta,
        estadoModal: state.estadoModal,
        formulario: state.formulario,
        tipoModal: state.tipoModal,
        codigos: state.codigos,
        codigo: state.codigo,
        abrirModal,
        cerrarModal,
        listaCodigos,
        agregarCodigo,
        eliminarCodigo,
        actualizarCodigo,
        mostrarFormulario,
        guardarCodigoActual,
        cleanStatusRespuesta,
      }}
    >
      {props.children}
    </CodigoContext.Provider>
  );
};

export default CodigoState;
