import React, { useContext, useEffect, useReducer, useState } from "react";
import ReporteContext from "./reporteContext";
import ReporteReducer from "./reporteReducer";
import jsPDF from "jspdf";
import Logo from "../../assets/img/logo/logoPDF.png";
import HeaderReporte from "../../assets/img/reportes/Header.png";
import FooterReporte from "../../assets/img/reportes/Footer.png";
import proyectoContext from "../proyectos/proyectoContext";
import clienteAxios from "../../config/axios";
import swal from "sweetalert";
import OpenSansRegular from "../../assets/fonts/OpenSans-Regular-normal"; // no quitar
import OpenSansSemiBold from "../../assets/fonts/OpenSans-SemiBold-normal"; // no quitar
import OpenSansBold from "../../assets/fonts/OpenSans-Bold-normal"; // no quitar

import { ACTIVAR_DESCARGA_ORDEN, DESACTIVAR_DESCARGA_ORDEN } from "../../types";

const ReporteState = (props) => {
  const initialState = {
    stOrdenCompraPDF: null,
    activarDescargaOrden: false,
    stPresupuestoPDF: null,
  };

  const empresas = [
    {
        id: "1",
        nombre: "Empresa de Gestion Estrategica",
        email: "nicolas@hatt.cl",
        direccion: "Colo Colo 222 Concepcion",
        ciudad: "Concepción",
        rut: "77.102.163-8",
        giro: "Gestión Comercial",
        telefono: "569-77097851",
    },
    {
        id: "2",
        nombre: "Hatt Producciones SpA",
        email: "contacto@empresanueva.cl",
        direccion: "San Martin 553 Concepcion",
        ciudad: "Santiago",
        rut: "77.949.279-6",
        giro: "Servicios Generales",
        telefono: "569-12345678",
    },
    // Agrega más empresas según sea necesario
];

  const [state, dispatch] = useReducer(ReporteReducer, initialState);

  const ProyectoContext = useContext(proyectoContext);
  const {
    modulosEvento,
    cleanStatusModulosEvento,
    stGenerarPresupuesto,
    cleanGenerarPresupuesto,
    fndesactivarOrdencompra,
    stActivarOrdenCompra,
    datosOrden,
    statusModuloOrden,
    modulosProveedorEvento,
    cleanStatusModulosOrden,
  } = ProyectoContext;

  const [idCliente, setIdCliente] = useState(null);
  const setEmpresaSeleccionada = (empresaId) => {
    setSelectedEmpresa(empresaId); // Actualiza el estado con el ID de la empresa seleccionada
    console.log('empresa se setio: ' + empresaId);
    console.log(empresaId);
  };
  const [datosCliente, setDatosCliente] = useState([]);
  const [dataEvento, setDataEvento] = useState([]);
  const [dataEventoOrden, setDataEventOrden] = useState([]);
  const [allDataOrdenPDF, setAllDataOrdenPDF] = useState([]);

  const [selectedEmpresa, setSelectedEmpresa] = useState(null);


  var modEnEvento = modulosEvento !== null && modulosEvento.data;
  var statusModEnEvento = modulosEvento !== null && modulosEvento.status;

  const usuario = JSON.parse(localStorage.getItem("usuario"));

  useEffect(() => {
    // PRESUPUESTO
    if (
      statusModEnEvento === 200 &&
      idCliente !== null &&
      stGenerarPresupuesto
    ) {
      const fetchData = async () => {
        var cliente_evento = await clienteAxios.get("api/cliente/" + idCliente);
        setDatosCliente(cliente_evento);
      };
      fetchData();
    }

    if (
      statusModEnEvento === 200 &&
      datosCliente.length !== 0 &&
      stGenerarPresupuesto
    ) {
      fnGenerarPresupuesto();
      cleanStatusModulosEvento();
      cleanGenerarPresupuesto();
    }
    // FIN PRESUPUESTO
    // ORDEN
    if (
      statusModuloOrden === 200 &&
      datosOrden.status === 200 &&
      stActivarOrdenCompra === true
    ) {
      cleanStatusModulosOrden();
      setAllDataOrdenPDF({
        dataEvento: dataEventoOrden.evento,
        dataProveedor: dataEventoOrden.proveedor,
        dataOrden: datosOrden.data,
        dataModulosOrden: modulosProveedorEvento,
      });
    }

    if (Object.keys(allDataOrdenPDF).length > 0 && stActivarOrdenCompra) {
      fnOrdenCompraPDF();
      setAllDataOrdenPDF([]);
      fndesactivarOrdencompra();
    }
    // FIN ORDEN
  }, [
    stActivarOrdenCompra,
    allDataOrdenPDF,
    modulosEvento,
    modEnEvento,
    datosOrden,
    statusModEnEvento,
    datosCliente,
    stGenerarPresupuesto,
    state.activarDescargaOrden,
    dataEventoOrden,
    modulosProveedorEvento,
    statusModuloOrden,
  ]);

  const fnActivarInicioPDFOrden = () => {
    dispatch({
      type: ACTIVAR_DESCARGA_ORDEN,
    });
  };

  const fnDesactivarInicioPDFOrden = () => {
    dispatch({
      type: DESACTIVAR_DESCARGA_ORDEN,
    });
  };

  const fnORdenPDF = (data) => {
    swal({
      title:
        "Sí existe una orden de compra asociada al proveedor, su reporte se descargará en breve",
      icon: "success",
      dangerMode: true,
    });
    setDataEventOrden({
      proveedor: data.data.proveedor,
      evento: data.data.evento,
    });
  };

  const fnOrdenCompraPDF = async () => {
    var fechaOrden = new Date();
    var fOrden = new Date();
    var fechaFinal =
      fOrden.getDate() +
      "/" +
      (fOrden.getMonth() + 1) +
      "/" +
      fOrden.getFullYear();
    var añoActual = fechaOrden.getFullYear().toString().slice(0, -2);

    const {
      dataEvento,
      dataProveedor,
      dataOrden,
      dataModulosOrden,
    } = allDataOrdenPDF;
    try {
      const dataToGenerate = {
        evento: {
          evento: dataEvento.nombre === undefined ? "" : dataEvento.nombre,
          encargado:
            dataEvento.encargado === undefined ? "" : dataEvento.encargado,
          fechaEnvioOC: dataOrden[0].fOrdenCompra,
          fechaRealizacion:
            dataEvento.fPresupuesto === undefined
              ? ""
              : dataEvento.fPresupuesto,
          lugarRealizacion:
            dataEvento.direccion === undefined ? "" : dataEvento.direccion,
          tipoFactura:
            dataEvento.tipoFactura === undefined ? "" : dataEvento.tipoFactura,
        },
        proveedor: {
          señor: dataProveedor.nombre === undefined ? "" : dataProveedor.nombre,
          direccion:
            dataProveedor.direccion === undefined
              ? ""
              : dataProveedor.direccion,
          mail: 'asdasdasdas',
          contacto:
            dataProveedor.contacto === undefined ? "" : dataProveedor.contacto,
          rut: dataProveedor.rut === undefined ? "" : dataProveedor.rut,
          ciudad:
            dataProveedor.comuna === undefined ? "" : dataProveedor.comuna,
          fono:
            dataProveedor.celular === undefined ? "" : dataProveedor.celular,
        },
        orden: {
          nOrden: `${dataOrden[0].id + 100}-${añoActual}`,
          observaciones: dataOrden[0].observaciones,
          formaPago: dataOrden[0].formaPago,
          totalPagado: dataOrden[0].totalPagado,
          tipoFactura: dataOrden[0].tipoFactura,
        },
        modulosOrden: dataModulosOrden,
      };

      const { evento, proveedor, orden, modulosOrden } = dataToGenerate;

      var doc = new jsPDF("p", "mm", [279, 216]);

      doc.addImage(HeaderReporte, "PNG", 0, 0, 216, 30);

      // Texto Orden Compra
      doc.setFont("OpenSans-Bold", "normal");
      doc.setTextColor(36, 51, 86);
      doc.setFontSize(12);
      doc.text("ORDEN DE COMPRA", 148, 13);
      doc.setFont("OpenSans-SemiBold", "normal");
      doc.setTextColor(102, 171, 203);
      doc.setFontSize(18);
      doc.text(`N° ${orden.nOrden}`, 159, 21);

      doc.setFont("OpenSans-SemiBold", "normal");
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(11);

      doc.text("Señor(es): ", 27, 37);
      doc.text("Dirección: ", 27, 42);
      doc.text("Mail: ", 27, 47);
      doc.text("Contacto: ", 27, 52);

      doc.text("Rut: ", 114, 42);
      doc.text("Ciudad: ", 114, 47);
      doc.text("Fono: ", 114, 52);

      doc.setFont("OpenSans-Regular", "normal");
      doc.text('HOLA HOLA HOLA', 47, 42);
      doc.text(proveedor.señor, 47, 37);
      doc.text(proveedor.mail, 38, 47);
      doc.text(proveedor.contacto, 47, 52);

      doc.text(proveedor.rut, 123, 42);
      doc.text(proveedor.ciudad, 130, 47);
      doc.text(proveedor.fono, 125, 52);

      doc.setFont("OpenSans-Bold", "normal");
      doc.setTextColor(36, 51, 86);
      doc.text("RAZÓN SOCIAL: HATT SPA - RUT: 77.102.163-8", 27, 63);

      doc.setLineWidth(1);
      doc.setDrawColor(102, 171, 203);
      doc.line(27, 65, 182, 65);
      doc.setLineWidth(0.45);

      doc.setFont("OpenSans-SemiBold", "normal");
      doc.setTextColor(0, 0, 0);

      doc.text("Razón Social: ", 27, 71);
      doc.text("Mail: ", 27, 76);
      doc.text("Dirección: ", 27, 81);

      doc.text("Rut: ", 114, 71);
      doc.text("Giro: ", 114, 76);
      doc.text("Fono: ", 114, 81);

      doc.setFont("OpenSans-Regular", "normal");

      doc.text("Hatt SpA", 52, 71);
      doc.text("nicolas@hatt.cl", 37, 76);
      doc.text("O'higgins 680, Galería Oliveri", 47, 81);
      doc.text("Concepción", 27, 86);

      doc.text("77.102.163-8", 123, 71);
      doc.text("Gestión Comercial", 124, 76);
      doc.text("569-77097851", 125, 81);

      doc.setFont("OpenSans-Bold", "normal");
      doc.setTextColor(36, 51, 86);
      doc.text("LO SIGUIENTE", 27, 97);

      doc.setLineWidth(1);
      doc.setDrawColor(102, 171, 203);
      doc.line(27, 99, 182, 99);

      doc.setLineWidth(2.3);
      doc.setDrawColor(36, 51, 86);
      doc.line(27, 104, 182, 104);

      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.3);
      doc.line(27, 105.2, 182, 105.2);

      doc.setTextColor(36, 51, 86);

      doc.setFontSize(9);
      doc.text("CANTIDAD", 29, 110);
      doc.text("DETALLE", 90, 110);
      doc.text("NETO", 145, 110);
      doc.text("TOTAL", 166, 110);

      doc.line(27, 113, 182, 113);

      // var pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(11);
      var y = 115;
      var b = 115;
      var objTextA = {
        align: "justify",
      };
      modulosOrden.map((modulo, index) => {
        doc.setTextColor(60, 60, 60);
        var splitText = doc.splitTextToSize(modulo.descripcion, 115);
        doc.setFont("OpenSans-Regular", "normal");
        doc.setFontSize(9);

        var a = y;
        var d = a + 5;
        var espacioTexto_1 = y;
        for (var i = 0; i < splitText.length; i++) {
          if (y > 275) {
            y = 137;
            doc.addPage();
          }

          doc.setLineWidth(d - a);
          if ((index + 1) % 2) {
            doc.setDrawColor(240, 240, 240);
          } else if (!((index + 1) % 2)) {
            doc.setDrawColor(255, 255, 255);
          }

          doc.line(27, a, 182, y - (y - a));
          doc.setDrawColor(0, 0, 0);
          doc.text(52, espacioTexto_1 + 1, splitText[i], objTextA);
          // doc.line(27, a, 182, y);
          espacioTexto_1 = espacioTexto_1 + 3.5;
          a = a + 5;
          d = d + 5;
        }
        doc.setFont("OpenSans-Bold", "normal");

        var largoCantidad = modulo.cantidad;
        doc.text(
          new Intl.NumberFormat("de-DE")
            .format(Math.trunc(modulo.cantidad))
            .toString(),
          largoCantidad <= 9
            ? 36
            : largoCantidad <= 99
            ? 35
            : largoCantidad <= 999
            ? 34
            : largoCantidad <= 9999
            ? 33
            : largoCantidad <= 99999 && 32,
          y + 1
        );
        var largoNeto = modulo.valor;
        doc.text(
          new Intl.NumberFormat("de-DE")
            .format(Math.trunc(modulo.valor))
            .toString(),
          largoNeto <= 9
            ? 157
            : largoNeto <= 99 && largoNeto > 9
            ? 154
            : largoNeto <= 999 && largoNeto > 99
            ? 153
            : largoNeto <= 9999 && largoNeto > 999
            ? 150
            : largoNeto <= 99999 && largoNeto > 9999
            ? 149
            : largoNeto <= 999999 && largoNeto > 99999
            ? 148
            : largoNeto <= 9999999 && largoNeto > 999999
            ? 145
            : largoNeto <= 99999999 && largoNeto > 9999999 && 144,
          y + 1
        );

        var largoTotal = modulo.valor * modulo.cantidad;
        doc.text(
          new Intl.NumberFormat("de-DE")
            .format(
              Math.trunc(
                orden.tipoFactura === "Exento"
                  ? modulo.valor * modulo.cantidad
                  : (modulo.valor + modulo.valor * 0.19) * modulo.cantidad
              )
            )
            .toString(),
          largoTotal <= 9
            ? 177
            : largoTotal <= 99 && largoTotal > 9
            ? 174
            : largoTotal <= 999 && largoTotal > 99
            ? 173
            : largoTotal <= 9999 && largoTotal > 999
            ? 170
            : largoTotal <= 99999 && largoTotal > 9999
            ? 169
            : largoTotal <= 999999 && largoTotal > 99999
            ? 168
            : largoTotal <= 9999999 && largoTotal > 999999
            ? 165
            : largoTotal <= 99999999 && largoTotal > 9999999 && 164,
          y + 1
        );
        y = y + 5;
        b = b + 5;
      });

      doc.setLineWidth(0.3);
      doc.line(27, 202, 182, 202);

      var splitTextFP = doc.splitTextToSize(
        orden.formaPago === null ? "" : orden.formaPago,
        90
      );

      doc.setFontSize(11);
      var y2 = 192;
      var objText = {
        align: "justify",
      };
      doc.setFont("OpenSans-Regular", "normal");
      doc.setFontSize(9);
      for (var i2 = 0; i2 < splitTextFP.length; i2++) {
        if (y2 > 275) {
          y2 = 207;
          doc.addPage();
        }
        doc.text(52, y2, splitTextFP[i2], objText);
        y2 = y2 + 3.5;
      }

      doc.setFont("OpenSans-Bold", "normal");

      doc.text("FORMA", 31.5, 193);
      doc.text("DE", 34.5, 196.5);
      doc.text("PAGO", 32.5, 199.5);

      doc.text("NETO", 147.5, 192);
      doc.setLineWidth(4.5);
      doc.setDrawColor(240, 240, 240);
      doc.line(142, 195, 182, 195);
      doc.text("IVA", 149, 196);
      doc.setDrawColor(36, 51, 86);
      doc.setTextColor(255, 255, 255);
      doc.line(142, 199.8, 182, 199.8);
      doc.text("TOTAL", 147, 201);
      doc.setLineWidth(0.8);
      doc.line(27, 202.5, 182, 202.5);

      doc.setTextColor(0, 0, 0);

      doc.setLineWidth(0.3);
      doc.setDrawColor(0, 0, 0);

      var totalNeto = orden.totalPagado;
      var totalIva = orden.tipoFactura === "Exento" ? 0 : totalNeto * 0.19;
      var totalEvento =
        orden.tipoFactura === "Exento" ? totalNeto : totalNeto + totalIva;
      var largoTotalNeto = totalNeto;
      doc.text(
        new Intl.NumberFormat("de-DE").format(Math.trunc(totalNeto)).toString(),
        largoTotalNeto <= 9
          ? 177
          : largoTotalNeto <= 99 && largoTotalNeto > 9
          ? 174
          : largoTotalNeto <= 999 && largoTotalNeto > 99
          ? 173
          : largoTotalNeto <= 9999 && largoTotalNeto > 999
          ? 170
          : largoTotalNeto <= 99999 && largoTotalNeto > 9999
          ? 169
          : largoTotalNeto <= 999999 && largoTotalNeto > 99999
          ? 168
          : largoTotalNeto <= 9999999 && largoTotalNeto > 999999
          ? 165
          : largoTotalNeto <= 99999999 && largoTotalNeto > 9999999 && 164,
        192
      ); // VALOR NETO
      var largoTotalIva = totalIva;
      doc.text(
        new Intl.NumberFormat("de-DE").format(Math.trunc(totalIva)).toString(),
        largoTotalIva <= 9
          ? 177
          : largoTotalIva <= 99 && largoTotalIva > 9
          ? 174
          : largoTotalIva <= 999 && largoTotalIva > 99
          ? 173
          : largoTotalIva <= 9999 && largoTotalIva > 999
          ? 170
          : largoTotalIva <= 99999 && largoTotalIva > 9999
          ? 169
          : largoTotalIva <= 999999 && largoTotalIva > 99999
          ? 168
          : largoTotalIva <= 9999999 && largoTotalIva > 999999
          ? 165
          : largoTotalIva <= 99999999 && largoTotalIva > 9999999 && 164,
        196.5
      ); // VALOR IVA
      doc.setTextColor(255, 255, 255);
      var largoTotalEvento = totalEvento;
      doc.text(
        new Intl.NumberFormat("de-DE")
          .format(Math.trunc(totalEvento))
          .toString(),
        largoTotalEvento <= 9
          ? 175
          : largoTotalEvento <= 99 && largoTotalEvento > 9
          ? 174
          : largoTotalEvento <= 999 && largoTotalEvento > 99
          ? 173
          : largoTotalEvento <= 9999 && largoTotalEvento > 999
          ? 170
          : largoTotalEvento <= 99999 && largoTotalEvento > 9999
          ? 169
          : largoTotalEvento <= 999999 && largoTotalEvento > 99999
          ? 168
          : largoTotalEvento <= 9999999 && largoTotalEvento > 999999
          ? 165
          : largoTotalEvento <= 99999999 && largoTotalEvento > 9999999 && 164,
        201
      ); // VALOR TOTAL
      doc.setTextColor(0, 0, 0);
      doc.line(27, 202, 182, 202);
      doc.line(142, 193, 182, 193);
      doc.line(142, 197.5, 182, 197.5);

      // VERTICALES TABLA
      doc.line(27, 188, 182, 188); // Linea Horizontal 2 Tabla
      doc.line(27, 105.2, 27, 202); // Linea Vertical 1 - Tabla
      doc.line(48, 105.2, 48, 202); // Linea Vertical 2 - Tabla
      doc.line(142, 105.2, 142, 202); // Linea Vertical 3 - Tabla
      doc.line(162, 105.2, 162, 202); // Linea Vertical 4 - Tabla
      doc.line(182, 105.2, 182, 202); // Linea 5 Vertical Tabla

      doc.setFont("OpenSans-SemiBold", "normal");
      doc.setFontSize(10);
      doc.text("EVENTO:", 27, 210);
      doc.text("ENCARGADO:", 27, 215);
      doc.text("FECHA ENVIO OC:", 27, 220);
      doc.text("FECHA REALIZACION:", 27, 225);
      doc.text("LUGAR REALIZACION:", 27, 230);
      doc.setFontSize(10);
      doc.setTextColor(36, 51, 86);
      doc.text("OBSERVACIONES:", 27, 240);
      doc.setLineWidth(1);
      doc.setDrawColor(102, 171, 203);
      doc.line(27, 242, 100, 242);

      doc.setFont("OpenSans-Regular", "normal");
      doc.text(evento.evento, 44, 210);
      doc.text(evento.encargado === null ? "" : evento.encargado, 50, 215);

      doc.text(
        evento.fechaEnvioOC === null
          ? ""
          : `${new Date(evento.fechaEnvioOC).toLocaleString("es-ES", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}`,

        57,
        220
      );
      doc.text(
        evento.fechaRealizacion === null
          ? ""
          : `${new Date(evento.fechaRealizacion).toLocaleString("es-ES", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}`,
        64,
        225
      );
      doc.text(evento.lugarRealizacion, 65, 230);

      var splitText3 = doc.splitTextToSize(orden.observaciones, 95);
      doc.setFontSize(11);
      var y3 = 247;
      var objText2 = {
        align: "justify",
      };
      doc.setFont("OpenSans-Regular", "normal");
      doc.setFontSize(9);
      for (var i3 = 0; i3 < splitText3.length; i3++) {
        if (y3 > 275) {
          y3 = 247;
          doc.addPage();
        }
        doc.text(27, y3, splitText3[i3], objText2);
        y3 = y3 + 5;
      }

      doc.setFont("OpenSans-Bold", "normal");
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);

      doc.setLineWidth(0.4);
      doc.setDrawColor(0, 0, 0);
      doc.line(144, 235, 181, 235);

      doc.text(usuario.nombre, 146, 239);
      doc.text("Hatt SpA", 155, 243);

      doc.addImage(FooterReporte, "PNG", 0, 256, 216, 23);
      // Guardar
      doc.save(`orden-compra-${orden.nOrden}.pdf`);
      window.open(doc.output(`bloburl`));
      cleanStatusModulosOrden();
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, asegúrese de haber creado la orden de compra para el proveedor`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 3000,
        button: false,
      });
    }
  };

  const fnPresupuestoPDF = async (evento, selectedEmpresa) => {
    console.log("Evento seleccionado:", evento);
  console.log("ID de la empresa:", selectedEmpresa);
    swal({
      title: "Su reporte se descargará en breve",
      icon: "success",
      dangerMode: true,
    });
    setIdCliente(evento.cliente_evento);
    setDataEvento(evento);
  };

  const fnGenerarPresupuesto = async () => {
    var evento = dataEvento;
    var datosEvento = evento;
    var fecha = new Date();
    var datosClienteLocal = datosCliente.data;

    var f = new Date();
    var fechaFinal =
      f.getDate() + "/" + (f.getMonth() + 1) + "/" + f.getFullYear();
    var añoActual = fecha.getFullYear().toString().slice(0, -2);
    const datosEmpresa = empresas.find(emp => emp.id === selectedEmpresa);
    if (!datosEmpresa) {
        swal("Error", "No se encontró la empresa seleccionada", "error");
        return;
    }
    try {
      const ContextOrdenCompra = {
        fecha: fechaFinal,
        evento: {
          servicio: datosEvento.servicio,
          nProyecto: `${datosEvento.id + 100}-${añoActual}`,
          observacionCliente: datosEvento.observacionCliente,
          formaPago: datosEvento.formaPago,
          cm: datosEvento.cm,
          tipoFactura: datosEvento.tipoFact,
          totalCobrado: datosEvento.totalCobrado,
        },
        cliente: {
          id: 1,
          nombre: datosClienteLocal.nombre,
          direccion: datosClienteLocal.direccion,
          rut: datosClienteLocal.rut,
          email: datosClienteLocal.email,
          comuna: datosClienteLocal.comuna,
          region: "Región",
          contacto: datosClienteLocal.contacto,
          observaciones: "Observaciones",
          telefono: datosClienteLocal.telefono,
        },
        modulosEvento: modEnEvento,
      };

      const { evento, cliente, modulosEvento, fecha } = ContextOrdenCompra;
      var doc = new jsPDF("p", "mm", [279, 216]);

      doc.addImage(HeaderReporte, "PNG", 0, 0, 216, 30);

      // PRESUPUESTO Y N° OC
      doc.setFont("OpenSans-Bold", "normal");
      doc.setTextColor(36, 51, 86);
      doc.setFontSize(12);
      doc.text(fecha, 190, 6);
      doc.text("PRESUPUESTO", 159, 13);
      doc.setFont("OpenSans-SemiBold", "normal");
      doc.setTextColor(102, 171, 203);
      doc.setFontSize(18);
      doc.text(`N° ${evento.nProyecto}`, 159, 21);
      doc.setFont("OpenSans-SemiBold", "normal");
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(11);

      // texto columna 1
      doc.text("Señor(es): ", 27, 37);
      doc.text("Servicio: ", 27, 42);
      doc.text("Ciudad: ", 27, 47);
      doc.text("Atención a:", 27, 52);

      // texto columna 2
      doc.text("Mail: ", 114, 42);
      doc.text("Rut:", 114, 47);
      doc.text("CM: ", 114, 52);

      doc.setFont("OpenSans-Regular", "normal");

      // valores columna 1
      doc.text(cliente.nombre, 47, 37);
      doc.text(evento.servicio, 45, 42);
      doc.text(cliente.comuna, 43, 47);
      doc.text(cliente.contacto, 50, 52);

      // valores columna 2
      doc.text(cliente.email, 124, 42);
      doc.text(cliente.rut, 123, 47);
      doc.text(evento.cm, 123, 52);

      doc.setFont("OpenSans-Bold", "normal");
      doc.setTextColor(36, 51, 86);
      doc.text("RAZÓN SOCIAL: " + datosEmpresa.nombre + " - RUT: " + datosEmpresa.rut, 27, 63);

      doc.setLineWidth(1);
      doc.setDrawColor(102, 171, 203);
      doc.line(27, 65, 182, 65);
      doc.setLineWidth(0.45);

      doc.setFont("OpenSans-SemiBold", "normal");
      doc.setTextColor(0, 0, 0);

      doc.text("Razón Social: ", 27, 71);
      doc.text("Mail: ", 27, 76);
      doc.text("Dirección: ", 27, 81);

      doc.text("Rut: ", 114, 71);
      doc.text("Giro: ", 114, 76);
      doc.text("Fono: ", 114, 81);

      doc.setFont("OpenSans-Regular", "normal");

      doc.text(datosEmpresa.nombre, 52, 71);
      doc.text(datosEmpresa.email, 37, 76);
      doc.text(datosEmpresa.direccion, 47, 81);
      doc.text(datosEmpresa.ciudad, 27, 86);

      doc.text(datosEmpresa.rut, 123, 71);
      doc.text(datosEmpresa.giro, 124, 76);
      doc.text(datosEmpresa.telefono, 125, 81);

      doc.setFont("OpenSans-Bold", "normal");
      doc.setTextColor(36, 51, 86);
      doc.text("LO SIGUIENTE", 27, 97);

      doc.setLineWidth(1);
      doc.setDrawColor(102, 171, 203);
      doc.line(27, 99, 182, 99);

      doc.setLineWidth(2.3);
      doc.setDrawColor(36, 51, 86);
      doc.line(27, 104, 182, 104);

      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.3);
      doc.line(27, 105.2, 182, 105.2);

      doc.setTextColor(36, 51, 86);

      doc.setFontSize(9);
      doc.text("CANTIDAD", 29, 110);
      doc.text("DETALLE", 90, 110);
      doc.text("NETO", 145, 110);
      doc.text("TOTAL", 166, 110);

      doc.line(27, 113, 182, 113);

      // var pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(11);
      var y = 115;
      var b = 115;
      var objTextA = {
        align: "justify",
      };
      modulosEvento.map((modulo, index) => {
        doc.setTextColor(60, 60, 60);
        var splitText = doc.splitTextToSize(modulo.descripcion, 90);
        doc.setFont("OpenSans-Regular", "normal");
        doc.setFontSize(9);

        var a = y;
        var d = a + 5;
        var espacioTexto_1 = y;
        for (var i = 0; i < splitText.length; i++) {
          if (y > 275) {
            y = 137;
            doc.addPage();
          }

          doc.setLineWidth(d - a);
          if ((index + 1) % 2) {
            doc.setDrawColor(240, 240, 240);
          } else if (!((index + 1) % 2)) {
            doc.setDrawColor(255, 255, 255);
          }

          doc.line(27, a, 182, y - (y - a));
          doc.setDrawColor(0, 0, 0);
          doc.text(52, espacioTexto_1 + 1, splitText[i], objTextA);
          // doc.line(27, a, 182, y);
          espacioTexto_1 = espacioTexto_1 + 3.5;
          a = a + 5;
          d = d + 5;
        }
        doc.setFont("OpenSans-Bold", "normal");

        var largoCantidad = modulo.cantidad;
        doc.text(
          new Intl.NumberFormat("de-DE")
            .format(Math.trunc(modulo.cantidad))
            .toString(),
          largoCantidad <= 9
            ? 36
            : largoCantidad <= 99
            ? 35
            : largoCantidad <= 999
            ? 34
            : largoCantidad <= 9999
            ? 33
            : largoCantidad <= 99999 && 32,
          y + 1
        );
        var largoNeto = modulo.valor;
        doc.text(
          new Intl.NumberFormat("de-DE")
            .format(Math.trunc(modulo.valor))
            .toString(),
          largoNeto <= 9
            ? 157
            : largoNeto <= 99 && largoNeto > 9
            ? 154
            : largoNeto <= 999 && largoNeto > 99
            ? 153
            : largoNeto <= 9999 && largoNeto > 999
            ? 150
            : largoNeto <= 99999 && largoNeto > 9999
            ? 149
            : largoNeto <= 999999 && largoNeto > 99999
            ? 148
            : largoNeto <= 9999999 && largoNeto > 999999
            ? 145
            : largoNeto <= 99999999 && largoNeto > 9999999 && 144,
          y + 1
        );
        var largoTotal = modulo.valor * modulo.cantidad;
        doc.text(
          new Intl.NumberFormat("de-DE")
            .format(Math.trunc(modulo.valor * modulo.cantidad))
            .toString(),
          largoTotal <= 9
            ? 177
            : largoTotal <= 99 && largoTotal > 9
            ? 174
            : largoTotal <= 999 && largoTotal > 99
            ? 173
            : largoTotal <= 9999 && largoTotal > 999
            ? 170
            : largoTotal <= 99999 && largoTotal > 9999
            ? 169
            : largoTotal <= 999999 && largoTotal > 99999
            ? 168
            : largoTotal <= 9999999 && largoTotal > 999999
            ? 165
            : largoTotal <= 99999999 && largoTotal > 9999999 && 164,
          y + 1
        );

        y = y + 5;
        b = b + 5;
      });

      doc.setLineWidth(0.3);
      doc.line(27, 202, 182, 202);

      var splitTextFP = doc.splitTextToSize(
        evento.formaPago === null ? "" : evento.formaPago,
        90
      );
      doc.setFontSize(11);
      var y2 = 192;
      var objText = {
        align: "justify",
      };
      doc.setFont("OpenSans-Regular", "normal");
      doc.setFontSize(9);
      for (var i2 = 0; i2 < splitTextFP.length; i2++) {
        if (y2 > 275) {
          y2 = 207;
          doc.addPage();
        }
        doc.text(52, y2, splitTextFP[i2], objText);
        y2 = y2 + 3.5;
      }

      doc.setFont("OpenSans-Bold", "normal");

      doc.text("FORMA", 31.5, 193);
      doc.text("DE", 34.5, 196.5);
      doc.text("PAGO", 32.5, 199.5);

      doc.text("NETO", 147.5, 192);
      doc.setLineWidth(4.5);
      doc.setDrawColor(240, 240, 240);
      doc.line(142, 195, 182, 195);
      doc.text("IVA", 149, 196);
      doc.setDrawColor(36, 51, 86);
      doc.setTextColor(255, 255, 255);
      doc.line(142, 199.8, 182, 199.8);
      doc.text("TOTAL", 147, 201);
      doc.setLineWidth(0.8);
      doc.line(27, 202.5, 182, 202.5);

      doc.setTextColor(0, 0, 0);

      doc.setLineWidth(0.3);
      doc.setDrawColor(0, 0, 0);
      // doc.setFontSize(9);

      var totalNeto = evento.totalCobrado;
      var totalIva = evento.tipoFactura === "Exento" ? 0 : totalNeto * 0.19;
      var totalEvento =
        evento.tipoFactura === "Exento" ? totalNeto : totalNeto + totalIva;
      var largoTotalNeto = totalNeto;
      doc.text(
        new Intl.NumberFormat("de-DE").format(Math.trunc(totalNeto)).toString(),
        largoTotalNeto <= 9
          ? 177
          : largoTotalNeto <= 99 && largoTotalNeto > 9
          ? 174
          : largoTotalNeto <= 999 && largoTotalNeto > 99
          ? 173
          : largoTotalNeto <= 9999 && largoTotalNeto > 999
          ? 170
          : largoTotalNeto <= 99999 && largoTotalNeto > 9999
          ? 169
          : largoTotalNeto <= 999999 && largoTotalNeto > 99999
          ? 168
          : largoTotalNeto <= 9999999 && largoTotalNeto > 999999
          ? 165
          : largoTotalNeto <= 99999999 && largoTotalNeto > 9999999 && 164,
        192
      ); // VALOR NETO
      var largoTotalIva = totalIva;
      doc.text(
        new Intl.NumberFormat("de-DE").format(Math.trunc(totalIva)).toString(),
        largoTotalIva <= 9
          ? 177
          : largoTotalIva <= 99 && largoTotalIva > 9
          ? 174
          : largoTotalIva <= 999 && largoTotalIva > 99
          ? 173
          : largoTotalIva <= 9999 && largoTotalIva > 999
          ? 170
          : largoTotalIva <= 99999 && largoTotalIva > 9999
          ? 169
          : largoTotalIva <= 999999 && largoTotalIva > 99999
          ? 168
          : largoTotalIva <= 9999999 && largoTotalIva > 999999
          ? 165
          : largoTotalIva <= 99999999 && largoTotalIva > 9999999 && 164,
        196.5
      ); // VALOR IVA
      doc.setTextColor(255, 255, 255);
      var largoTotalEvento = totalEvento;
      doc.text(
        new Intl.NumberFormat("de-DE")
          .format(Math.trunc(totalEvento))
          .toString(),
        largoTotalEvento <= 9
          ? 175
          : largoTotalEvento <= 99 && largoTotalEvento > 9
          ? 174
          : largoTotalEvento <= 999 && largoTotalEvento > 99
          ? 173
          : largoTotalEvento <= 9999 && largoTotalEvento > 999
          ? 170
          : largoTotalEvento <= 99999 && largoTotalEvento > 9999
          ? 169
          : largoTotalEvento <= 999999 && largoTotalEvento > 99999
          ? 168
          : largoTotalEvento <= 9999999 && largoTotalEvento > 999999
          ? 165
          : largoTotalEvento <= 99999999 && largoTotalEvento > 9999999 && 164,
        201
      ); // VALOR TOTAL
      doc.setTextColor(0, 0, 0);
      doc.line(27, 202, 182, 202);
      doc.line(142, 193, 182, 193);
      doc.line(142, 197.5, 182, 197.5);

      // VERTICALES TABLA
      doc.line(27, 188, 182, 188); // Linea Horizontal 2 Tabla
      doc.line(27, 105.2, 27, 202); // Linea Vertical 1 - Tabla
      doc.line(48, 105.2, 48, 202); // Linea Vertical 2 - Tabla
      doc.line(142, 105.2, 142, 202); // Linea Vertical 3 - Tabla
      doc.line(162, 105.2, 162, 202); // Linea Vertical 4 - Tabla
      doc.line(182, 105.2, 182, 202); // Linea 5 Vertical Tabla

      doc.setFont("OpenSans-Bold", "normal");
      doc.setFontSize(11);
      doc.setTextColor(36, 51, 86);
      doc.text("OBSERVACIONES:", 27, 217);
      doc.setLineWidth(1);
      doc.setDrawColor(102, 171, 203);
      doc.line(27, 219, 100, 219);

      var splitText3 = doc.splitTextToSize(evento.observacionCliente, 95);
      doc.setFontSize(11);
      var y3 = 224;
      var objText2 = {
        align: "justify",
      };
      doc.setFont("OpenSans-Regular", "normal");
      doc.setFontSize(9);
      for (var i3 = 0; i3 < splitText3.length; i3++) {
        if (y3 > 275) {
          y3 = 250;
          doc.addPage();
        }
        doc.text(27, y3, splitText3[i3], objText2);
        y3 = y3 + 5;
      }
      doc.setFont("OpenSans-Bold", "normal");
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 0);

      doc.setLineWidth(0.4);
      doc.setDrawColor(0, 0, 0);
      doc.line(144, 235, 181, 235);

      doc.text(usuario.nombre, 146, 239);
      doc.text("Hatt SpA", 155, 243);

      doc.addImage(FooterReporte, "PNG", 0, 256, 216, 23);
      doc.save(`Presupuesto-${evento.nProyecto}.pdf`);
      window.open(doc.output(`bloburl`));
      cleanStatusModulosEvento();
      cleanGenerarPresupuesto();
    } catch (e) {
      swal({
        title: `Hubo un error, por favor, intente nuevamente`,
        text: "Esta mensaje se cerrará automáticamente",
        icon: "error",
        timer: 2000,
        button: false,
      });
    }
  };

  return (
    <ReporteContext.Provider
      value={{
        stOrdenCompraPDF: state.stOrdenCompraPDF,
        stPresupuestoPDF: state.stPresupuestoPDF,
        activarDescargaOrden: state.activarDescargaOrden,
        setEmpresaSeleccionada,
        fnPresupuestoPDF,
        fnActivarInicioPDFOrden,
        fnDesactivarInicioPDFOrden,
        fnORdenPDF,
      }}
    >
      {props.children}
    </ReporteContext.Provider>
  );
};

export default ReporteState;
