import {
  LISTADO_PRESTAMOS,
  REGISTRAR_PRESTAMO,
  PRESTAMO_ERROR,
  FORMULARIO_PRESTAMO,
  ELIMINAR_PRESTAMO,
  PRESTAMO_ACTUAL,
  GENERAR_PDF_PRESTAMO,
  LIMPIAR_GENERAR_PRESTAMO,
  PRESTAMOS_ESTABLECIMIENTOS,
  LIMPIAR_STATUS,
  PDF_PRESTAMO_EXISTENTE,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case FORMULARIO_PRESTAMO:
      return {
        ...state,
        formulario: true,
      };
    case LIMPIAR_GENERAR_PRESTAMO:
      return {
        ...state,
        generarpdfprestamo: null,
        statusAgregar: null,
        respuestaEliminar: null,
      };
    case LIMPIAR_STATUS:
      return {
        ...state,
        statusAgregar: null,
        respuestaEliminar: null,
      };
    case PRESTAMOS_ESTABLECIMIENTOS:
      return {
        ...state,
        prestamosestablecimientos: action.payload,
      };

    case LISTADO_PRESTAMOS:
      return {
        ...state,
        prestamos: action.payload,
      };
    case REGISTRAR_PRESTAMO:
      return {
        ...state,
        prestamos: [...state.prestamos, action.payload],
        formulario: false,
        errorformulario: false,
        statusAgregar: action.status,
      };

    case GENERAR_PDF_PRESTAMO:
    case PDF_PRESTAMO_EXISTENTE:
      return {
        ...state,
        generarpdfprestamo: action.payload,
        estadoModal: true,
        formulario: false,
        statusAgregar: action.status,
      };
    case ELIMINAR_PRESTAMO:
      return {
        ...state,
        prestamos: state.prestamos.filter(
          (prestamo) => prestamo.id !== action.payload
        ),
        prestamoSeleccionado: null,
        respuestaEliminar: action.respuestaEliminar,
      };
    case PRESTAMO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };
    case PRESTAMO_ACTUAL:
      return {
        ...state,
        prestamoSeleccionado: action.payload,
      };
    default:
      return state;
  }
};
