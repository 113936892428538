import {
  LISTADO_EVENTOS,
  LISTADO_CLIENTES,
  LISTADO_PROOVEDORES,
  CONTABILIDAD,
  MODULOS_EVENTO,
  CLEAN_STATUS_MODULOS,
  CLEAN_STATUS_MODULOS_ORDEN,
  GENERAR_PDF_PROPUESTA,
  CLEAN_GENERAR_REPORTE_PROPUESTA,
  CLEAN_GENERAR_REPORTE_ORDEN,
  EVENTOS_POR_FECHA,
  MODULOS_ORDEN_PROVEEDOR_EVENTO,
  DATOS_ORDEN,
  ACTIVAR_ORDEN_COMPRA,
  DESACTIVAR_ORDEN_COMPRA,
  LECTURA_ORDEN_COMPRA,
  COMPRAS_VENTAS_EVENTOS,
  CLEAN_STATUS_COMPRAS_VENTAS,
  TOTAL_VENTAS_COMPRAS_EVENTO,
  CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO,
  CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO_CONSULTAR,
  CLEAN_ST_STATUS_FN_GETPROVEEDORES_FOR_ID_EVENT,
  CLEAN_STATUS_ORDENES_EVENTO,
  PROVEEDORES_EVENTO,
  ORDENES_EVENTO,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case LISTADO_EVENTOS:
      return {
        ...state,
        eventos: action.payload,
      };

    case ACTIVAR_ORDEN_COMPRA:
      return {
        ...state,
        stActivarOrdenCompra: true,
      };

    case DESACTIVAR_ORDEN_COMPRA:
      return {
        ...state,
        stActivarOrdenCompra: false,
      };

    case LECTURA_ORDEN_COMPRA:
      return {
        ...state,
        datosLecturaOrden: action.payload,
      };

    case EVENTOS_POR_FECHA:
      return {
        ...state,
        eventosPorFecha: action.payload,
        ordenesPorFecha: action.payload2,
      };

    case GENERAR_PDF_PROPUESTA:
      return {
        ...state,
        stGenerarPresupuesto: true,
      };
    case CLEAN_GENERAR_REPORTE_PROPUESTA:
      return {
        ...state,
        stGenerarPresupuesto: false,
      };

    case CLEAN_GENERAR_REPORTE_ORDEN:
      return {
        ...state,
        stGenerarOrden: false,
      };

    case MODULOS_ORDEN_PROVEEDOR_EVENTO:
      return {
        ...state,
        modulosProveedorEvento: action.payload,
        statusModuloOrden: action.statusModuloOrden,
      };

    case LISTADO_CLIENTES:
      return {
        ...state,
        clientes: action.payload,
      };

    case LISTADO_PROOVEDORES:
      return {
        ...state,
        proveedores: action.payload,
      };

    case CONTABILIDAD:
      return {
        ...state,
        contabilidad: action.payload,
      };

    case MODULOS_EVENTO:
      return {
        ...state,
        modulosEvento: action.payload,
      };

    case CLEAN_STATUS_MODULOS:
      return {
        ...state,
        modulosEvento: null,
      };

    case CLEAN_STATUS_MODULOS_ORDEN:
      return {
        ...state,
        statusModuloOrden: null,
      };

    case DATOS_ORDEN:
      return {
        ...state,
        datosOrden: action.payload,
      };

    case ORDENES_EVENTO:
      return {
        ...state,
        stOrdenesEvento: action.payload,
        stStatusfnGetOrdenesEvento: action.status,
      };

    case CLEAN_STATUS_ORDENES_EVENTO:
      return {
        ...state,
        stStatusfnGetOrdenesEvento: null,
      };

    case COMPRAS_VENTAS_EVENTOS:
      return {
        ...state,
        stComprasVentasEventos: action.payload,
        stStatusComprasVentas: action.status,
      };

    case CLEAN_STATUS_COMPRAS_VENTAS:
      return {
        ...state,
        stStatusComprasVentas: null,
      };

    case TOTAL_VENTAS_COMPRAS_EVENTO:
      return {
        ...state,
        stTotalComprasVentasEvento: action.payload.data,
        stStatusTotalIngEgEvento: action.payload.status,
      };

    case CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO_CONSULTAR:
      return {
        ...state,
        stStatusTotalIngEgEvento: null,
      };

    case CLEAN_STATUS_TOTAL_COMPRAS_VENTAS_EVENTO:
      return {
        ...state,
        stTotalStatusComprasVentasEvento: null,
      };

    case PROVEEDORES_EVENTO:
      return {
        ...state,
        stProveedoresEvento: action.payload,
        stStatusfnGetProveedoresForIdEvent: action.status,
      };
    case CLEAN_ST_STATUS_FN_GETPROVEEDORES_FOR_ID_EVENT:
      return {
        ...state,
        stStatusfnGetProveedoresForIdEvent: null,
      };
    default:
      return state;
  }
};
