import React from "react";

export default function FormularioLogin({
  onSubmit,
  email,
  password,
  onChange,
}) {
  return (
    <>
      <form className="login" onSubmit={onSubmit}>
        <h3 className="tituloLogin">HATT | Estrategias & Gestión</h3>
        <input
          type="text"
          id="email"
          name="email"
          placeholder="su correo"
          className="user"
          value={email}
          onChange={onChange}
          autoComplete="off"
          required
        />
        <input
          type="password"
          id="password"
          name="password"
          className="password"
          placeholder="su contraseña"
          value={password}
          onChange={onChange}
          autoComplete="off"
          required
        />
        <button className="btnLogin" type="submit">
          Entrar
        </button>
      </form>
    </>
  );
}
