import React, { useReducer } from "react";
import ColegioContext from "./colegioContext";
import ColegioReducer from "./colegioReducer";
import clienteAxios from "../../config/axios";

import {
  OBTENER_COLEGIO,
  COLEGIO_ERROR,
  REGISTRAR_COLEGIO,
  VALIDAR_FORMULARIO_COLEGIO,
  FORMULARIO_COLEGIO,
  ELIMINAR_COLEGIO,
  ACTUALIZAR_COLEGIO,
  COLEGIO_ACTUAL,
  COLEGIO_DETALLES,
  COLEGIOS_USUARIO,
  DETALLES_COLEGIO_SELECCIONADO,
  LIMPIAR_COLEGIOS,
  COLEGIO_INVENTARIO,
  NOMBRE_U_EDUCATIVA,
  OCULTAR_ACCIONES,
  CLEAN_STATUS_INVENTARIO,
  FILTRAR_ARTICULOS,
  ARTICULO_ERROR,
  CLEAN_STATUS_FILTRO,
  COLEGIO_INVENTARIO_DISPONIBLE,
  ABRIR_MODAL,
  CERRAR_MODAL,
} from "../../types";
import swal from "sweetalert";
import { useEffect } from "react";

const ColegioState = (props) => {
  const initialState = {
    colegios: [],
    colegiosusuario: [],
    formulario: false,
    errorformulario: false,
    colegio: null,
    colegioSeleccionado: null,
    colegioDetalles: null,
    detallescolegioseleccionado: null,
    inventariocolegio: [],
    mostrarendash: true,
    statusinventario: null,
    inventariocolegiodisponible: [],
    statusinventariodisponible: null,
    estadoModal: false,
    statusRespuesta: null,
    tipoModal: null,
  };

  // Crear dispatch y state
  const [state, dispatch] = useReducer(ColegioReducer, initialState);

  // Serie de funciones para el CRUD

  const mostrarEnDash = () => {
    dispatch({
      type: OCULTAR_ACCIONES,
    });
  };

  const mostrarFormulario = () => {
    dispatch({
      type: FORMULARIO_COLEGIO,
    });
  };

  const cleanStatusInventario = () => {
    dispatch({
      type: CLEAN_STATUS_INVENTARIO,
    });
  };

  // Obtener colegios
  const obtenerColegios = async () => {
    try {
      const resultado = await clienteAxios.get("/api/sucursales");
      dispatch({
        type: OBTENER_COLEGIO,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
      };
      dispatch({
        type: COLEGIO_ERROR,
        payload: alerta,
      });
    }
  };

  // Obtener colegios de un usuario
  const obtenerColegiosUsuario = async (id) => {
    try {
      const resultado = await clienteAxios.get(`/api/sucursales/${id}`);
      dispatch({
        type: COLEGIOS_USUARIO,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
      };
      dispatch({
        type: COLEGIO_ERROR,
        payload: alerta,
      });
    }
  };

  // Obtener detalles colegio seleccionado
  const detallesColegio = async (id) => {
    try {
      const resultado = await clienteAxios.get(`/api/sucursales/${id}`);
      dispatch({
        type: DETALLES_COLEGIO_SELECCIONADO,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
      };
      dispatch({
        type: COLEGIO_ERROR,
        payload: alerta,
      });
    }
  };

  // Obtener detalles colegio seleccionado
  const nombreUEducativa = async (id) => {
    try {
      const resultado = await clienteAxios.get(`/api/sucursales/${id}`);
      dispatch({
        type: NOMBRE_U_EDUCATIVA,
        payload: resultado.data,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
      };
      dispatch({
        type: COLEGIO_ERROR,
        payload: alerta,
      });
    }
  };

  // AGREGAR NUEVA COLEGIO
  const agregarColegio = async (colegio) => {
    try {
      const resultado = await clienteAxios.post("/api/sucursales", colegio);
      const alerta = swal({
        title: "Sucursal creada correctamente",
        text: "Este mesaje se cerrará automáticamente",
        icon: "success",
        timer: 2000,
        button: false,
      });
      // Insertar el colegio en el state
      dispatch({
        type: REGISTRAR_COLEGIO,
        payload: resultado,
        alerta,
      });
      obtenerColegios();
    } catch (error) {
      // const alerta = swal({
      //   title: `${error.response.data.errors[0].message}`,
      //   text: "Este mesaje se cerrará automáticamente",
      //   icon: "error",
      //   timer: 2000,
      //   button: false,
      // });
      dispatch({
        type: COLEGIO_ERROR,
        // payload: alerta,
      });
    }
  };
  // FIN AGREGAR NUEVA COLEGIO

  // ELIMINAR UNA COLEGIO
  const eliminarColegio = async (id) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Una vez eliminado, no podra recuperar los datos!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Eliminar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await clienteAxios.delete(`/api/sucursales/${id}`);
          dispatch({
            type: ELIMINAR_COLEGIO,
            payload: id,
          });
          swal({
            title: "Sucursal eliminada!",
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: `Hubo un error intente nuevamente`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };
  // FIN ELIMINAR UNA COLEGIO

  // Valida el formulario por errores
  const mostrarError = () => {
    dispatch({
      type: VALIDAR_FORMULARIO_COLEGIO,
    });
  };

  // Edita o modifica un establecimiento
  const actualizarColegio = async (colegio) => {
    swal({
      title: "¿Está seguro?",
      text: "¡Está a punto de modificar un establecimiento!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Modificar",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        const resultado = await clienteAxios.put(
          `/api/sucursales/${colegio.id}`,
          colegio
        );
        if (willDelete && resultado.status === 200) {
          dispatch({
            type: ACTUALIZAR_COLEGIO,
            payload: resultado,
          });

          swal({
            title: `${
              resultado.data === "ok" ? "¡Sucursal modificada!" : ""
            }`,
            text: "Este mesaje se cerrará automáticamente",
            icon: "success",
            timer: 2000,
            button: false,
          });

          obtenerColegios();
        } else {
          swal({
            title: "¡Operación cancelada!",
            text: "Este mesaje se cerrará automáticamente",
            timer: 2000,
            button: false,
          });
        }
      })
      .catch((error) => {
        if (error) {
          swal({
            title: "Hubo un error, intente nuevamente",
            text: "Este mesaje se cerrará automáticamente",
            icon: "error",
            timer: 2000,
            button: false,
          });
        } else {
          swal.stopLoading();
          swal.close();
        }
      });
  };

  // detalles colegio actual
  const guardarColegioDetalles = (colegio) => {
    dispatch({
      type: COLEGIO_DETALLES,
      payload: colegio,
    });
  };

  // Extrae la colegio actual
  const guardarColegioActual = (colegio) => {
    dispatch({
      type: COLEGIO_ACTUAL,
      payload: colegio,
    });
  };

  const colegioInventario = async (limite, pagina, id) => {
    try {
      const resultado = await clienteAxios.get(
        `/api/articulosU/${limite}/${pagina}/${id}`
      );
      dispatch({
        type: COLEGIO_INVENTARIO,
        payload: resultado,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
      };
      dispatch({
        type: COLEGIO_ERROR,
        payload: alerta,
      });
    }
  };

  const colegioInventarioDisponible = async (limite, pagina, id, ruta) => {
    try {
      const resultado = await clienteAxios.get(
        `/api/articulosDisponibles/${limite}/${pagina}/${id}`
      );
      dispatch({
        type: COLEGIO_INVENTARIO_DISPONIBLE,
        payload: resultado,
      });
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
      };
      dispatch({
        type: COLEGIO_ERROR,
        payload: alerta,
      });
    }
  };

  // FILTRO DE BUSQUEDA ARTICULOS
  const filtroBusqueda = async (datos) => {
    try {
      const resultado = await clienteAxios.post(`/api/articulosFiltro`, datos);
      dispatch({
        type: FILTRAR_ARTICULOS,
        payload: resultado.data,
        statusFiltro: resultado.status,
      });
    } catch (error) {
      const alerta = swal({
        title: `Hubo un error, intente nuevamente`,
        text: "Este mesaje se cerrará automáticamente",
        icon: "error",
        timer: 2500,
        button: false,
      });
      dispatch({
        type: ARTICULO_ERROR,
        payload: alerta,
      });
    }
  };

  const cleanStatusFiltro = () => {
    dispatch({
      type: CLEAN_STATUS_FILTRO,
    });
  };
  // FILTRO DE BUSQUEDA ARTICULOS

  // Limpiar colegios
  const limpiarColegios = () => {
    dispatch({
      type: LIMPIAR_COLEGIOS,
    });
  };

  const abrirModal = (categoriaModal) => {
    dispatch({
      type: ABRIR_MODAL,
      payload: categoriaModal,
    });
  };
  const cerrarModal = () => {
    dispatch({
      type: CERRAR_MODAL,
    });
  };

  return (
    <ColegioContext.Provider
      value={{
        inventariocolegiodisponible: state.inventariocolegiodisponible,
        detallescolegioseleccionado: state.detallescolegioseleccionado,
        statusinventariodisponible: state.statusinventariodisponible,
        mostrarventasanuales: state.mostrarventasanuales,
        colegioSeleccionado: state.colegioSeleccionado,
        nombresueducativa: state.nombresueducativa,
        inventariocolegio: state.inventariocolegio,
        statusinventario: state.statusinventario,
        errorformulario: state.errorformulario,
        colegiosusuario: state.colegiosusuario,
        estadoModal: state.estadoModal,
        formulario: state.formulario,
        colegios: state.colegios,
        colegio: state.colegio,
        statusRespuesta: state.statusRespuesta,
        estadoModal: state.estadoModal,
        tipoModal: state.tipoModal,
        abrirModal,
        cerrarModal,
        mostrarError,
        mostrarEnDash,
        filtroBusqueda,
        agregarColegio,
        detallesColegio,
        obtenerColegios,
        limpiarColegios,
        eliminarColegio,
        nombreUEducativa,
        mostrarFormulario,
        actualizarColegio,
        colegioInventario,
        cleanStatusFiltro,
        guardarColegioActual,
        cleanStatusInventario,
        obtenerColegiosUsuario,
        guardarColegioDetalles,
        colegioInventarioDisponible,
      }}
    >
      {props.children}
    </ColegioContext.Provider>
  );
};

export default ColegioState;
