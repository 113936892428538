import {
  ABRIR_MODAL,
  CERRAR_MODAL,
  CREAR_CUENTA,
  USUARIO_ERROR,
  MOFICAR_PERFIL,
  USUARIO_ACTUAL,
  OBTENER_USUARIO,
  ELIMINAR_USUARIO,
  DETALLES_USUARIO,
  REGISTRAR_USUARIO,
  ENVIAR_CONTRASEÑA,
  FORMULARIO_USUARIO,
  ACTUALIZAR_USUARIO,
  VALIDAR_FORMULARIO,
  VINCULAR_USUARIO_COLEGIOS,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case FORMULARIO_USUARIO:
      return {
        ...state,
        formulario: true,
      };
    case OBTENER_USUARIO:
      return {
        ...state,
        usuarios: action.payload,
      };
    case REGISTRAR_USUARIO:
      return {
        ...state,
        usuarios: [...state.usuarios, action.payload.data],
        formulario: false,
        errorformulario: false,
        statusRespuesta: action.payload.status,
      };

    case CREAR_CUENTA:
      return {
        ...state,
        nuevaCuenta: action.payload.body.empresa,
      };

    case VINCULAR_USUARIO_COLEGIOS:
      return {
        ...state,
        datosVinculacion: [...state.datosVinculacion, action.payload],
        formulario: false,
        errorformulario: false,
      };
    case DETALLES_USUARIO:
      return {
        ...state,
        detallesusuario: action.payload,
      };

    case VALIDAR_FORMULARIO:
      return {
        ...state,
        errorformulario: true,
      };
    case ELIMINAR_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.filter(
          (usuario) => usuario.id !== action.payload
        ),
        usuarioSeleccionado: null,
      };

    case ENVIAR_CONTRASEÑA:
      return {
        ...state,
        idContraseña: action.payload,
      };

    case ACTUALIZAR_USUARIO:
      return {
        ...state,
        usuarios: state.usuarios.map((usuario) =>
          usuario.id === action.payload.id ? action.payload : usuario
        ),
        estadoModal: true,
        statusRespuesta: action.payload.status,
      };
    case MOFICAR_PERFIL:
      return {
        ...state,
        usuarios: state.usuarios,
        estadoModal: true,
      };
    case USUARIO_ACTUAL:
      return {
        ...state,
        usuarioSeleccionado: action.payload,
      };
    case USUARIO_ERROR:
      return {
        ...state,
        mensaje: action.payload,
      };

    case ABRIR_MODAL:
      return {
        ...state,
        estadoModal: true,
        tipoModal:
          action.payload === "tipoAgregar"
            ? "tipoAgregar"
            : action.payload === "tipoDetalles"
            ? "tipoDetalles"
            : action.payload === "tipoEditar"
            ? "tipoEditar"
            : null,
        statusRespuesta: null,
      };

    case CERRAR_MODAL:
      return {
        ...state,
        estadoModal: false,
        statusRespuesta: null,
        tipoModal: null,
      };
    default:
      return state;
  }
};
