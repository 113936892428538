import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../../context/autenticacion/authContext";
import Spinner from "../spinner/Spinner";
import FormularioLogin from "./FormularioLogin";
import Video from "../../assets/video/Fondo4.mp4";
import swal from "sweetalert";
import Logo from "../../assets/img/logo/logoPDF.png";

const Login = (props) => {
  const authContext = useContext(AuthContext);
  const {
    mensaje,
    autenticado,
    iniciarSesion,
    usuario,
    clearMensaje,
  } = authContext;

  const [verificar, setVerificar] = useState(true);
  const [ingreso, setIngreso] = useState(false);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    if (
      autenticado &&
      usuario !== null &&
      localStorage.getItem("token") !== "" &&
      localStorage.getItem("usuario") !== ""
    ) {
      setTimeout(() => {
        setVerificar(false);
        setTimeout(() => {
          setIngreso(true);
          setTimeout(() => {
            props.history.push("/dashboard");
          }, 500);
        }, 700);
      }, 800);
    }
    if (mensaje && autenticado !== true) {
      setTimeout(() => {
        setSpinner(false);
        clearMensaje();
      }, 1500);
    }
    // eslint-disable-next-line
  }, [mensaje, autenticado, usuario, props.history]);

  // State para iniciar sesión
  const [usuarioLocal, guardarUsuario] = useState({
    email: "",
    password: "",
  });

  // extraer de usuario
  const { email, password } = usuarioLocal;

  const onChange = (e) => {
    guardarUsuario({
      ...usuarioLocal,
      [e.target.name]: e.target.value,
    });
  };

  // Cuando el usuario quiere iniciar sesión
  const onSubmit = (e) => {
    e.preventDefault();

    // Validar que no haya campos vacios
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      swal({
        title: "Debe ingresar un correo válido",
        text: "Este mesaje se cerrará automáticamente",
        timer: 2000,
        icon: "error",
        button: false,
      });
      return;
    }
    if (password.length < 6) {
      swal({
        title: "El password debe ser de al menos 6 carácteres",
        text: "Este mesaje se cerrará automáticamente",
        timer: 2000,
        icon: "error",
        button: false,
      });
      return;
    }

    if (autenticado !== true) {
      setSpinner(true);
    }
    // Pasarlo al action
    iniciarSesion({ email, password });
  };

  return (
    <>
      <div className="contenedorGeneralLogin col-md-12">
        <video autoPlay="autoPlay" muted loop="loop" id="myVideo">
          <source src={Video} type="video/mp4" />
        </video>
        <img
          src={Logo}
          width="200"
          height="60"
          className="logoLogin"
          alt=""
        />
        <div>
          {usuario === null || autenticado !== true || spinner === false ? (
            spinner ? (
              <>
                <form className="login">
                  <h3 className="tituloLogin">Enviado Datos...</h3> <br />
                  <Spinner />
                  {mensaje ? (
                    <h3 className="tituloLogin"> {mensaje.msg} </h3>
                  ) : (
                    <br />
                  )}
                </form>
              </>
            ) : (
              <>
                <FormularioLogin
                  onSubmit={onSubmit}
                  email={email}
                  password={password}
                  onChange={onChange}
                />
              </>
            )
          ) : autenticado ? (
            <form className="login">
              {verificar ? (
                <>
                  <h3 className="tituloLogin">Verificando...</h3> <br />
                  <br /> <Spinner />
                </>
              ) : ingreso ? (
                <>
                  <h3 className="tituloLogin">Ingresando al Sistema...</h3>
                  <br />
                  <br /> <Spinner />
                </>
              ) : (
                <>
                  <h3 className="tituloLogin">Datos correctos...</h3> <br />
                  <br /> <Spinner />
                </>
              )}
            </form>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Login;
